@import "../variables/vars"

.section-heading
    font-size: 18px

.section-header
    height: 65px !important

.icon-color:hover
    color: $site-theme-color
