.section-heading {
  font-size: 18px;
}

.section-header {
  height: 65px !important;
}

.icon-color:hover {
  color: #0dd6b8;
}