/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css'
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel'
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default'
@import '~swiper/swiper.min.css'
@import "~@ng-select/ng-select/themes/default.theme.css"

@import '~ngx-toastr/toastr'
@import '~video.js/dist/video-js.css'
// @import '~ngx-toastr/toastr-bs4-alert'

// @import '~bootstrap/scss/functions'
// @import '~bootstrap/scss/variables'
// @import '~bootstrap/scss/mixins'
// @import '~ngx-toastr/toastr-bs4-alert'

@import './vars'

@import url('https://cdn.jsdelivr.net/npm/medium-editor@latest/dist/css/medium-editor.min.css')
@import url('https://cdn.jsdelivr.net/npm/medium-editor@latest/dist/css/themes/default.css')
// @import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined")
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp")

/* Default theme. ~960B */
@import '~@vime/core/themes/default.css'

/* Optional light theme (extends default). ~400B */
@import '~@vime/core/themes/light.css'

// $cyan-background: #11e1c2
$cyan-background: #0DD6B8
$cyan-secondary: #00c295

// $cyan-secondary: #00d1a8
// $cyan-secondary: #0D7377

div:focus
    outline: 0

.medium-editor-element.medium-editor-insert-plugin
    min-height: 300px

    &.medium-editor-placeholder
        border-top: 1px solid #ccc
        border-bottom: 1px solid #ccc

.medium-editor-insert-plugin.medium-editor-placeholder:after
    font-size: 16px
    color: rgba(0, 0, 0, 0.5)
    font-family: "Roboto, " Helvetica Neue ", sans-serif"
    padding: 10px
    font-style: unset

    & p
        color: #222
        font-size: 16px
        font-family: "Roboto, " Helvetica Neue ", sans-serif"

.medium-editor-insert-plugin:focus,
.medium-insert-embeds figure figcaption:focus,
.medium-insert-images figure figcaption:focus,
.mediumInsert figure figcaption:focus,
.mediumInsert-embeds figure figcaption:focus
    border-top: 1px solid #ccc
    border-bottom: 1px solid #ccc

.medium-insert-images.ui-sortable figure img
    width: 100%

button.medium-insert-action,
.medium-editor-insert-plugin .medium-insert-buttons button
    border: 1px solid #3256f4 !important

.fa-youtube-play:before,
.fa-camera:before
    background-image: linear-gradient(to right, #5d197f, #3256f4)
    background-clip: text
    -webkit-background-clip: text
    color: transparent

// .medium-editor-insert-plugin
// .medium-insert-buttons
// .medium-insert-buttons-show
span
    margin-top: -1px
    // background-image: linear-gradient(to right, #5d197f, #3256f4)
    background-clip: text
    // -webkit-background-clip: text
    // color: transparent
    // font-size: 28px
    line-height: 21px

.medium-editor-toolbar.medium-editor-stalker-toolbar.medium-toolbar-arrow-under.medium-editor-toolbar-active
    background-image: linear-gradient(to right, #5d197f, #3256f4)
    padding: 1px

button.medium-editor-action.medium-editor-action-h3.medium-editor-button-active
    background: rgb(228, 228, 228)

.medium-editor-toolbar li button
    padding: 12px
    font-size: 16px

.medium-toolbar-arrow-over:before,
.medium-toolbar-arrow-under:after
    content: none !important

.next__cancel
    border-radius: 41px !important
    margin-top: 1% !important
    margin-left: 35% !important
// Border radius for Images and Image containers
.img-border-radius
    border-radius: 1px !important

// Bookshelve icon hovering css
.btn-add-cart
    // &:hover
    //     // background-color: $site-theme-color !important

    //     .bookshelve-book
    //         color: $site-theme-color-secondary
    .bookshelve-book
        color: white!important
        margin-left: 3px
        // color: $site-theme-color

// Slider Previous and Next buttons
.nav-buttons
    display: flex
    margin: 20px 0 0 0

.previous-button-icon, .next-button-icon
    color: white
    transform: scale(1.5)
    z-index: 9999
    top: 32%
    position: absolute

.previous-button-icon
    left: 9px

.next-button-icon
    margin: 0 0 0 10px
    right: 9px

// custom class for angular dialog box
.custom-dialog-box .mat-dialog-container
    height: 610px
    width: 700px
    transform: none
    padding: 0px
    border-radius: 0px

.mat-dialog-container img
  display: revert
  max-width: 100%
  height: 100%

// .post-image-dialog-box
//     max-height: 50px

.grid-and-list-layout
    cursor: pointer

.global-button
    // background-image: linear-gradient(to right,#5d197f,#3256f4)
    background: $cyan-background!important
    color: white !important
    font-size: 13px !important
    text-transform: uppercase !important
    letter-spacing: 0px !important
    // border: none
    border-radius: 5px
    padding: 6px !important
    text-transform: capitalize !important

.div-container-margin-top
    margin-top: 100px

.book-section
    font-size: 2.3rem
    font-family: 'Roboto', sans-serif

.home-slider:after, .home-slider:before, .boxed-slider:after, .boxed-slider:before
    display: none !important

.book-details-icons
    height: $icon-size
    width: $icon-size

.book-interation
    display: flex
    left: 0px
    width: 50%

.last-updated-on
    font: 400 14px "Open Sans"
    margin: 0px
    color: #222329

.views-hearts-shares-bookshelves
    font-size: 1.5rem

.book-details-icons
    margin-right: 21%

.tag-list
    display: flex
    justify-content: space-between
    flex-wrap: wrap

hr
    &.dashed
        // border-top: 1px dashed inherit
        border-style: dashed
.book-tags
    margin: 10px -1px 0 6px
    font-size: 11px
    // border: 1px solid #6edfc1
    border-radius: 9px
    padding: 0 20px 0 20px
    height: 25px
    width: 84px
    transition-duration: 350ms
    display: flex
    justify-content: center
    align-items: center

.book-tags:hover
    // background-color: #6edfc1
    background-image: $site-theme-color !important
    color: white
    cursor: pointer

.add-cart
    border-color: $site-theme-color

.add-cart:before
    content: ""

.add-wishlist:before
    content: ""

.start-reading
    font-family: "Oswald", sans-serif
    font-size: 1.3rem
    padding: 20px
    font-weight: 400
    border-color: $site-theme-color
    width: 123.7px

.start-reading:hover
    transition-duration: 350ms
    background-image: $site-theme-color
    color: white
    border-color: white

.social-media
    width: 6%
    margin-bottom: 2%
    margin-right: 10px

.social-media-container
    display: flex

.soft-card
    background: #f8f9fa!important
    color: #828282
    font-size: 13px
    padding: 12px
    margin: 8px
    border-radius: 8px!important


.category-grid .home-product
    &:hover
        transform: translateY(0rem)!important

.fw-600
    font-weight: 600!important

.fs-13
    font-size: 13px!important

.fs-14
    font-size: 14px!important

.fs-16
    font-size: 16px!important

.fs-18
    font-size: 16px!important

.fs-20
    font-size: 16px!important

.br-8
    border-radius: 8px!important

.mat-icon
    vertical-align: middle!important

.color-cyan
    color: $cyan-background !important

.color-cyan-secondary
    color: #000000 !important

.btn.btn-cyan-container
    background: $cyan-background
    color: white!important
    border-radius: 8px!important

.btn-cyan
    background: $cyan-background!important
    color: white!important
    border-radius: 8px

    &:disabled
        opacity: 0.7

.btn.btn-cyan-2
    background: $cyan-secondary
    color: white
    border-radius: 8px

.background-cyan
    background: $cyan-background

.background-camel
    background: rgba(216, 118, 121, 0.2)

.background-grey
    background: rgba(157, 131, 143, 0.2)

.background-ice-blue
    background: rgba(75, 129, 158, 0.21)

.btn-add-cart
    background: $cyan-background!important

.multiselect-dropdown .dropdown-btn
  display: inline-block !important
  width: 100% !important
  padding: 6px 12px !important
  margin-bottom: 0 !important
  font-size: 22px !important
  font-weight: 400 !important
  line-height: 1.1 !important
  border:  1px !important
  border-top: 0 !important
  text-align: left !important
  vertical-align: middle !important
  cursor: pointer !important
  background-image: none !important
  border-radius: 4px !important

.multiselect-dropdown
  border-bottom: 1px solid #d1d1d1

.multiselect-item-checkbox input
  border: 2px solid #d1d1d1

@media ( max-width: 400px )
    .custom-dialog-box .mat-dialog-container
        height: 500px !important
        width: 500px !important

    body
        font-size: 2rem !important

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap'
@import './external'


.mat-icon.small
    font-size: 18px

.mat-dialog-container
    border-radius: 8px!important

.home-container
    width: 100% !important
    padding:0px 50px
    margin: 0 auto !important

.slick-slider
    width: 88%
    margin: auto

.swiper-book-gallery
    .slick-slider
        width: 100%
        margin: auto

body .slick-prev, body .slick-next
    height: 45px
    width: 40px
    z-index: 100

.first-slider
    .slick-slider
        width: 100% !important
        margin-top: 50px !important

.slick-slider
    .slick-prev
        left: 50px !important

.slick-slider
    .slick-next
        right: 50px !important

.slick-prev:before
    font-size:31px !important
    opacity:1 !important

.slick-next:before
    font-size:31px !important
    opacity:1 !important

.popular-reads-home-slider
    .slick-slider
        .slick-prev
          top: 40% !important
          left: -15px !important

    .slick-slider
        .slick-next
          top: 40% !important
          right: -15px !important

.newly-added-home-slider
    .slick-slider
        .slick-prev
          top: 40% !important
          left: -15px !important

    .slick-slider
        .slick-next
          top: 40% !important
          right: -15px !important

.books-slider
    .slick-slider
        .slick-next
          top: -35px
          right: 0px !important

    .slick-slider
        .slick-prev
          top: -35px
          left: 93% !important

.books-slider
    .slick-next:before
        color: #6deded !important

    .slick-prev:before
        color: #6deded !important

.featured-books-writer-section
    .slick-slider
        .slick-next
            border: 1px solid #48e8e8
            border-radius: 50%
            padding: 10px 15px
            top: 105%
            right: 34% !important
            // right: calc(100% - 57%) !important;
            width:60px !important
            height:60px !important
            :hover
                background-color:#000 !important
                color:#FFFFFF !important

    .slick-slider
        .slick-prev
            border: 1px solid #48e8e8
            border-radius: 50%
            padding: 10px 15px
            top: 105%
            left: 34% !important
            width:60px !important
            height:60px !important
            :hover
                background-color:#000 !important
                color:#FFFFFF !important

    .slick-next:before
        font-family: porto
        line-height:10px
        color: #6deded !important

    .slick-prev:before
        font-family: porto
        line-height:10px
        font-size:20px
        color: #6deded !important

@media (min-width:1400px) and (max-width:1600px)
    .featured-books-writer-section
        .slick-slider
            .slick-next
                right: 30% !important

        .slick-slider
            .slick-prev
                left: 30% !important

@media (max-width:1200px)
    .featured-books-writer-section
        .slick-slider
            .slick-next
                top: 103%
                right: 38% !important

        .slick-slider
            .slick-prev
                top: 103%
                left: 38% !important

    .books-slider
        .slick-slider
            .slick-next
                top: -35px
                right: 0px !important

        .slick-slider
            .slick-prev
                top: -35px
                left: 75% !important

@media (max-width:991px)
    .books-slider
        .slick-slider
            .slick-next
                top: -35px
                right: 0px !important

        .slick-slider
            .slick-prev
                top: -35px
                left: 80% !important

@media (max-width:768px)
    .featured-books-writer-section
        .slick-slider
            .slick-next
                top: 103%
                right: 35% !important

        .slick-slider
            .slick-prev
                top: 103%
                left: 35% !important

@media (max-width:500px)

    .our-picks-for-you-header
        font-size: 30px !important
        margin-top:30px !important

    .home-container
        padding:0px !important

    .our-picks-for-you-view-more-btn
        font-family: 'Open Sans'
        font-style: normal
        font-weight: 700
        font-size: 18px
        line-height: 25px
        color: #2AD7D7
        width: 100% !important
        height: 60px
        background: #c8f8f8
        border-radius: 14px
        margin-top: 30px
        margin-left: 0px !important

    .duration-btn-1
        border: 1px solid #F0F0F0
        padding: 15px 10px 15px 10px !important
        border-bottom-left-radius: 25px
        border-top-left-radius: 25px

    .duration-btn-2
        border: 1px solid #F0F0F0
        padding: 15px 10px 15px 10px !important

    .duration-btn-3
        border: 1px solid #F0F0F0
        padding: 15px 10px 15px 10px !important
        border-bottom-right-radius: 25px
        border-top-right-radius: 25px

    .duration-btn-block
        padding: 18px 0 18px 0
        width: 100% !important
        margin: 0 auto 40px auto
        text-align: center
        cursor: pointer

    .section-2
        margin-bottom: 50px !important

    .featured-book-block, .featured-writer-block
        h1
            font-size: 34px !important
            line-height: 30px !important

    .featured-books-writer-section
        width: 100%
        margin-bottom: 30px !important
        padding-bottom:60px !important
        padding-top:40px !important

    .writer-block
        h1
            font-family: 'Open Sans'
            font-style: normal
            font-weight: 700
            font-size: 23px !important
            line-height: 41px
            color: #292D32

    .books-slider
        .slick-slider
            .slick-prev
                top: -36px
                left: 70% !important

    .section-heading
        font-size: 18px !important

    .featured-books-writer-section
        .slick-slider
            .slick-next
                top: 101%
                right: 30% !important

        .slick-slider
            .slick-prev
                top: 101%
                left: 30% !important




@media (max-width:400px)
    .home-container
        padding:0px

    .featured-books-writer-section
        .slick-slider
            .slick-next
                top: 103%
                right: 24% !important

        .slick-slider
            .slick-prev
                top: 103%
                left: 24% !important

.best-seller-slider-component
    .slick-slider
        width: 63% !important
        margin: auto

.best-seller-slider-component
    .slick-slider
        .slick-next
            top: 55%
            right: -15% !important

    .slick-slider
        .slick-prev
            top: 55%
            left: -15% !important
    .writer-info
        h6
            font-family: 'Open Sans'
            font-style: normal
            font-weight: 400
            font-size: 14px
            line-height: 19px
            color: #AAAAAA
        h5
            font-family: 'Open Sans'
            font-style: normal
            font-weight: 600
            font-size: 16px !important
            line-height: 25px
            color: #292D32

h4
    margin-top: 26px !important

.notification-icons
    width:24px !important
    height:24px !important
    margin:0 auto !important

.header-icons
    border: none !important
    padding: 10px !important
    border-radius: 14px !important
    width: auto !important


.header-scarch-input
    height: 50px !important
    border-radius: 14px 0px 0px 14px !important
    box-shadow: none !important

.header-scarch-button
    width: 69px !important
    border: 1px solid #ced4da !important
    border-radius: 0px 14px 14px 0px !important
    justify-content: center !important
    background-color: transparent !important

.header-scarch-icone
    height: 20px !important
    width:20px

@media (max-width:400px)
    .our-picks-for-you-header
        margin-left: 19px !important
        font-weight: 700
        font-size: 28px !important
        line-height: 10px !important
        margin-top: 25px !important
        margin-bottom: 25px !important

    .book-block
        p
            font-size: 13px !important
            line-height: 18px !important
            color: #48E8E8

    .writer-info
        h5
            font-size: 14px !important
            line-height: 18px !important

    .book-block
        h4
            font-size: 23px !important
            line-height: 28px !important
            margin-top:10px !important

    .best-seller-slider-component
        .slick-slider
            // margin-left:42px !important

    .best-seller-slider-component
        .slick-slider
            .slick-next
                right: -5% !important

        .slick-slider
            .slick-prev
                left: -5% !important

@media (max-width:350px)
    .best-seller-slider-component
        .slick-slider
            margin-left:35px !important


// ngx pagination

.ngx-pagination .current
    background: $cyan-background!important
    border-radius: 4px!important


.mat-dialog-container
    border-radius: 8px!important

.card-shadow
    background: #ffffff
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.10)


.mat-tab-group.mat-primary .mat-ink-bar
  background-color: #11e1c2 !important
.br-15
    border-radius: 15px!important

.border-radius-10
    border-radius : 10px !important


.source-image
    max-height: 80vh!important


.font-poppins
    font-family: 'Poppins'!important


.link-blue-color
    color: #3256f4!important


input.error
    border-color: #dc3545!important

.btn-danger
    background-color: #dc3545 !important

.op-8
    opacity: .8

.input-box
    border: 2px solid lightgrey
    border-radius: 6px
    
    &:hover
        border-color: #11e1c2

