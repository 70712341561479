$icon-size: 15px
$button-height: 35px
$button-width: 20px
$views-hearts-shares-bookshlves-font-size: 1.4rem
$font-color-primary: #0dd6b8
$view-more-button-font-size: 14px
$button-color: #0dd6b8
$button-hover-color: #3256F4
$site-theme-color: #0dd6b8
$site-theme-color-secondary: #3256F4
