$icon-size: 15px
$button-height: 35px
$button-width: 20px
$views-hearts-shares-bookshlves-font-size: 1.4rem
$font-color-primary: #3256f4
$view-more-button-font-size: 14px
$image-tile-height: 310px
$image-tile-width: 210px
$button-color: #11e1c2
// $button-color: linear-gradient(to right,#5d197f,#3256f4)
$button-hover-color: #3256F4
// $site-theme-color: linear-gradient(to right,#5d197f,#3256f4)
$site-theme-color: #11e1c2
// $site-theme-color: linear-gradient(to right,#5d197f,#3256f4)
$site-theme-color-secondary: #11e1c2
$site-font-style: Roboto, 'Helvetica Neue', sans-serif
$site-font-style-secondary: Open Sans
$site-placeholder-font-size: 12px
$site-button-color-secondary: #11e1c2
