



.mCustomScrollBox 
  background-image: linear-gradient(481deg, #e65124, #9c9400, #27953e, #53479e)
  background-size: cover
  background-repeat: no-repeat
  background-position: 19% 50%

table.dataTable thead td, table.dataTable thead th
  border-bottom: 1px solid #dddddd 

a.side-menu__item img 
  margin-right: 8px
  width: 18px


.app-sidebar .side-menu li a 
  padding-top: 27px
  padding-bottom: 27px


a.side-menu__item span 
  color: #fff
  font-size: 14px
  font-weight: 600


.app-sidebar .side-menu li a 
  border-bottom: 1px solid rgba(255, 255, 255, 0.12)


.app-header .header-brand 
  background: #fff
  margin: 0 auto
  padding: 15px


.card-body.custom_card_body 
  position: relative


.card-body.custom_card_body:before 
  content: ""
  position: absolute
  width: 135px
  height: 100%
  left: 0
  top: 0
  background: #00c0ef
  border-radius: 5px 0px 0px 5px


.card-body.custom_card_body 
  position: relative


.card-body.custom_card_body::before 
  content: ""
  position: absolute
  max-width: 94px
  width: 100%
  height: 100%
  left: 0
  top: 0
  background: #f7f7f7
  border-radius: 5px 0px 0px 5px

.card-body.custom_card_body,
.card-body.custom_card_body_two,
.card-body.custom_card_body_three,
.card-body.custom_card_body_four 
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12)
  border-radius: 5px

.custom_img_card img 
  position: absolute
  left: 45px
  right: 0
  top: calc(50% - 25px)


.card-body.custom_card_body p.card-text.text-muted.font-weight-semibold.mb-1,
.card-body.custom_card_body_two
  font-size: 16px
  font-weight: 500 !important
  color: #222 !important
  padding-left: 220px
  margin-bottom: 2px !important


.card-body.custom_card_body .float-left.mt-2 h1,
.card-body.custom_card_body_two .float-left.mt-2 h1,
.card-body.custom_card_body_three .float-left.mt-2 h1,
.card-body.custom_card_body_four .float-left.mt-2 h1 
  font-size: 28px
  font-weight: 600
  color: #222
  padding-left: 220px
  margin: 0


.app-sidebar__toggle:before 
  content: ""
  background-image: url(../../src/assets/images/menu-right.png) 
  width: 100%
  max-width: 50px
  background-repeat: no-repeat
  height: 40px
  position: absolute


.app-sidebar__toggle 
  margin-top: 30px

.mdi-arrow-expand-all:before 
  color: #bdbdbd
  display: block


.nav-link.icon 
  width: 108px


a.nav-link.icon.full-screen-link 
  display: flex
  justify-content: center
  border-left: 1px solid rgba(151, 151, 151, 0.35)
  margin: 0
  height: 100%


a.nav-link.pr-0.leading-none 
  width: 219px
  display: flex
  align-items: center
  justify-content: space-around
  border-left: 1px solid rgba(151, 151, 151, 0.35)
  height: 100%


a.nav-link.pr-0.leading-none i 
  color: #bdbdbd
  font-size: 20px


a.nav-link.pr-0.leading-none h3 
  font-size: 16px
  font-weight: 400
  margin: 0
  color: #222


.d-flex.order-lg-2.ml-auto .dropdown 
  border: none

.app-sidebar__toggle 
  border-left: none


img.header-brand-img.d-none.d-sm-block 
  padding-top: 0
  width: 130px
  height: 39px
  margin-right: 60px
  width: 212px
  height: 67px


.avatar 
  background: none


table.table.table-hover.card-table.table-striped.table-vcenter.table-outline.text-nowrap
  font-size: 14px
  font-weight: 500
  color: #222


table.table.table-hover.card-table.table-striped.table-vcenter.table-outline.text-nowrap
  background: rgba(39, 68, 181, 0.06)


.card.custom_card 
  box-shadow: none
  background: none


.card-body.text-center.custom_card_body_chart 
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1)
  margin-top: 30px
  border-radius: 8px


.table-responsive.custom_table_responsive 
  background: #fff
  margin-top: 30px
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1)
  border-radius: 8px


.flex_datatable_row 
  display: flex
  align-items: center
  margin-bottom: 20px


.flex_datatable_row .flex_images img 
  margin-left: 10px


div#example_length label 
  display: flex
  align-items: center


select.custom-select.custom-select-sm.form-control.form-control-sm 
  margin: 0px 15px


div#example_filter 
  margin-top: 12px
  display: flex
  justify-content: flex-end


i.fa.fa-pencil 
  background: #32cafe


i.fa.fa-eye 
  background: #28afd0


i.fa.fa-trash 
  background: #ec2d38


i.fa.fa-pencil,
i.fa.fa-eye,
i.fa.fa-trash 
  width: 40px
  height: 40px
  display: flex
  align-items: center
  justify-content: center
  border-radius: 4px
  color: #fff
  font-size: 14px


.flex_fa_icons 
  display: flex
  align-items: center
  justify-content: center
  /* justify-content: space-around  Avtar */


.flex_a 
  display: flex
  flex-direction: column


.flex_a a 
  font-size: 14px
  color: #6b6f80
  margin-bottom: 10px


.flex_fa_icons.flex_fa_iocns_booking 
  justify-content: center


.flex_fa_icons.flex_fa_iocns_booking a 
  padding-right: 12px


.package_btn 
  min-width: 161px
  text-align: center
  display: inline-block
  line-height: 38px
  background: #1e87f0
  color: #fff
  font-size: 14px
  font-weight: 600
  outline: none
  border-radius: 4px
  border: 1px solid #1e87f0
  transition: 0.3s
  cursor: pointer


.package_btn:hover 
  background: #fff
  transition: 0.3s


a.package_btn:hover 
  color: #1e87f0
  transition: 0.3s


.package_btn a 
  color: #fff
  transition: 0.3s


.package_btn a i 
  padding-right: 10px


.card-header.custom_flex_header 
  display: flex
  align-items: center
  justify-content: space-between


.login_wraper 
  background-image: url(../../src/assets/images/login_form_bg.png)
  background-size: cover
  background-repeat: no-repeat
  height: 150vh
  width: 100%
  position: relative
  padding-bottom: 30px
  padding-left: 15px
  padding-right: 15px


.login_wraper:before 
  content: ""
  width: 100%
  height: 100%
  background: rgba(0, 0, 0, 0%)
  position: absolute
  left: 0
  top: 0
  z-index: -9


.login_logo 
  text-align: center
  padding-top: 70px
  margin: 0px 30px


.admin_txt 
  text-align: center


.admin_txt h2 
  font-size: 32px
  font-weight: 700
  color: #fff
  text-align: center
  margin-bottom: 30px
  margin-top: 50px


form.login_form 
  max-width: 555px
  margin: 0 auto
  background: #fff
  border-radius: 15px
  box-shadow: 0px 15px 18px rgba(0, 0, 0, 0.32)


.login_heading_txt 
  background: #3561e5
  border-radius: 15px 15px 0px 0px


.login_heading_txt h2 
  color: #fff
  font-size: 30px
  font-weight: 600
  padding-left: 30px
  padding-top: 30px
  padding-bottom: 30px


.login_label 
  margin-top: 50px


.login_label .login_input 
  position: relative


.login_label .login_input i 
  position: absolute
  top: calc(50% - 20px)
  left: 27px
  font-size: 24px
  opacity: 0.5


.login_label .login_input input 
  width: 100%
  background: #fff
  padding: 20px 20px
  border: 1px solid #cdcfd2
  border-radius: 50px
  margin-bottom: 17px
  outline: none
  padding-left: 60px
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.06)


.login_label_btn 
  padding: 0px 37px


button.login_btn 
  min-width: 479px
  background: #3561e5
  display: inline-block
  text-align: center
  border-radius: 50px
  font-size: 20px
  font-weight: 700
  color: #fff
  line-height: 60px
  border: none
  outline: none
  margin-top: 24px
  transition: 0.3s
  cursor: pointer


button.login_btn:hover 
  box-shadow: inset 479px 0px 0px rgba(0, 0, 0, 0.24)
  transition: 0.3s


.foget_passcode 
  border-top: 1px solid rgba(151, 151, 151, 0.33)
  padding: 20px 20px
  text-align: center
  margin-top: 30px


.foget_passcode a 
  font-size: 14px
  font-weight: 600
  color: #222


form.edit_form 
  background: #fff
  border-radius: 19px
  padding: 25px 30px
  margin-bottom: 40px


.edit_label 
  margin-bottom: 15px


.edit_label label 
  font-size: 14px
  font-weight: 500
  color: #6b6f80


.edit_label input 
  width: 100%
  background: #fff
  outline: none
  padding: 10px 10px


.edit_form_heading_txt 
  margin-top: 22px
  margin-bottom: 10px


.save_flex_btn 
  display: flex
  align-items: center
  margin-top: 20px


a.back_btn 
  /* min-width: 122px */
  display: inline-block
  text-align: center
  background: #fff
  border: 1px solid #979797
  border-radius: 4px
  line-height: 40px
  box-shadow: 5px 5px 25px 0px rgba(46, 61, 73, 0.2)
  transition: 0.3s
  outline: none


a.back_btn:hover 
  box-shadow: 2px 4px 8px 0px rgba(46, 61, 73, 0.2)
  transition: 0.3s


a.back_btn 
  color: #6b6b6b
  font-size: 14px
  font-weight: 600

.save_btn 
  line-height: 40px
  display: inline-block
  text-align: center
  outline: none
  background-image: linear-gradient(481deg, #e65124, #9c9400, #27953e, #53479e)
  font-size: 16px
  color: #fff
  font-family: "baskervillenormal"
  border-radius: 30px
  transition: 0.3s


.edit_form_heading_txt span 
  font-size: 20px
  font-weight: 700
  color: #6b6f80


.edit_form.change_passcode 
  max-width: 1104px
  margin: 0 auto
  margin-bottom: 40px


.edit_label.edit_label_passcode 
  position: relative


.edit_label.edit_label_passcode i 
  position: absolute
  top: 45px
  left: 30px


.edit_label.edit_label_passcode input 
  padding-left: 60px
  border: 1px solid #d2d2d2


.edit_label.edit_label_passcode label 
  font-size: 14px
  font-weight: 600
  color: #6b6f80


.main_heading_txt_edit 
  max-width: 1104px
  margin: 0 auto
  margin-top: 50px


.main_heading_txt_edit h2 
  font-size: 20px
  font-weight: 600


.save_flex_btn.save_flex_btn_change_passcode 
  margin-top: 40px


.app-content .side-app 
  padding-top: 70px


.edit_label select 
  font-size: 14px
  font-weight: 500
  color: #6b6f80
  width: 100%
  padding: 10px 10px
  background: #fff
  outline: none
  background-image: url(../../src/assets/images/tool-tip.png)
  background-repeat: no-repeat
  background-position: 99% 16px
  -webkit-appearance: none
  border: 1px solid #d2d2d2
  border-radius: inherit
  height: 45px
  padding-left: 10px


.edit_label textarea 
  width: 100%
  padding: 9px 9px
  border: 1px solid #d2d2d2
  resize: none
  outline: none


.login_input.custom_login_label i 
  top: 50px


.promocode_txt h2 
  font-size: 14px
  color: #6b6f80
  margin-bottom: 5px


.promocode_txt p 
  font-size: 16px
  color: #414141
  margin: 0


.promocode_txt 
  margin-bottom: 30px


.promocode_txt.promocode_txt_features 
  border-top: 1px solid rgba(151, 151, 151, 0.25)
  padding-top: 30px


.promocode_txt.promocode_txt_features p 
  max-width: 750px
  font-size: 16px
  line-height: 26px


.promocode_txt.promocode_txt_features.about_us_description_txt p 
  max-width: 100%


.packages_detailing_txt h2 
  font-size: 18px
  font-weight: 600
  margin-bottom: 20px


.service_image 
  width: 300px
  height: 200px
  overflow: hidden
  border-radius: 4px


.service_image img 
  width: 100%
  object-fit: cover
  border-radius: 4px


.sidebar-mini.sidenav-toggled .app-sidebar .side-menu__item.active 
  background: none


.app-content.my-3.my-md-5 
  background: #f1f3f6


.card-body.custom_card_body.card_bg_img
  background-image: url(../../src/assets/images/card_bg.svg)
  background-repeat: no-repeat
  background-position: 100% 0%


a.back_btn.save_btn.save_btn 
  line-height: 40px
  display: inline-block
  text-align: center
  outline: none
  background-image: linear-gradient(481deg, #e65124, #9c9400, #27953e, #53479e)
  font-size: 16px
  font-weight: 500
  color: #fff
  border-radius: 30px
  transition: 0.3s

.form_edit_book 
  width: 100%
  margin: auto
  padding: 66px 170px
  background: #fff
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.18)
  /* border-radius: 24px */


.heading_form h2 
  font-size: 31px
  font-weight: 700
  margin-bottom: 22px
  color: #222


.edit_img 
  margin-bottom: 52px


.input_txt 
  position: relative
  margin-bottom: 22px


.input_txt input 
  width: 100%
  padding: 14px 18px
  background: #f1f3f6
  border: none
  border-radius: 6px
  outline: none


.input_txt input::placeholder 
  font-size: 16px
  color: rgba(0, 0, 0, 0.5)


.input_txt textarea 
  width: 100%
  padding: 14px 18px
  background: #fff
  border: none
  border-radius: 6px
  outline: none
  resize: none
  border: 1px solid #ababab
  color: #222


.input_txt textarea::placeholder 
  font-size: 16px
  color: rgba(0, 0, 0, 0.5)


.input_txt select 
  width: 100%
  /* background: #f1f3f6 */
  /* border: none */
  border-radius: 6px
  outline: none
  height: 39px
  text-indent: 14px
  font-size: 16px
  background: #fff
    border: 1px solid #ababab
  color: rgba(0, 0, 0, 0.5)


.input_txt select::option 
  padding-left: 18px


.save 
  text-align: center


.save .save_btn 
  max-width: 200px
  width: 100%


.mt_mb 
  padding-top: 60px
  padding-bottom: 60px


.modal.fade.trash_popup 
  max-width: 785px
  width: 100%
  margin: auto !important
  border-radius: 0


.trash_popup .btn_group 
  display: flex
  align-self: center
  justify-content: space-between


.modal.fade.trash_popup .modal-body 
  padding: 68px 39px
  padding-top: 0


.modal.fade.trash_popup .save_btn,
.modal.fade.trash_popup .save_btn2 
  max-width: 170px
  width: 100%
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.17)


.modal.fade.trash_popup .save_btn 
  color: #fff


.modal.fade.trash_popup .save_btn.save_btn2 
  background: #f4f4f4
  color: #222


.modal.fade.trash_popup .modal-header 
  padding: 0
  border-bottom: none
  position: relative


.modal.fade.trash_popup .close 
  opacity: 1


.modal.fade.trash_popup .trash_heading h2 
  font-size: 16px
  color: #222
  font-weight: 600
  margin-bottom: 50px
  text-align: center
  padding-top: 46px


.modal.fade.trash_popup button.close 
  position: absolute
  right: -16px
  top: -20px
  outline: none


.user_card 
  position: relative
  text-align: right


.user_card h2 
  font-size: 18px
  font-weight: 700
  color: #222
  margin-bottom: 12px


.user_card p 
  font-size: 27px
  font-weight: 700
  color: #222
  margin-bottom: 12px
  margin-bottom: 0px


button.btn.btn-outline-secondary.border-left-0 
  background-image: linear-gradient(481deg, #e65124, #9c9400, #27953e, #53479e)
  border: none


button.btn.btn-outline-secondary.border-left-0 i 
  color: #fff
  top: 15px


#DataTables_Table_0_filter input 
  padding: 10px 15px


#DataTables_Table_0_filter input:focus,
#DataTables_Table_0_length select:focus 
  outline: none


.table-responsive:hover 
  border: none !important


.table-responsive::-webkit-scrollbar 
  width: 10px
  height: 1px

.table-responsive::-webkit-scrollbar-track 
  background: #fff

.table-responsive::-webkit-scrollbar-thumb 
  background: #fff

.table-responsive::-webkit-scrollbar-thumb:hover 
  background: #fff


th 
  text-transform: uppercase


input[type="search"] 
  outline: none
  padding-left: 6px


button 
  outline: none
  border: none


.custom_img_card i 
  font-size: 50px
  color: #fff
  position: absolute
  top: calc(50% - 25px)


.card-body.custom_card_body.fourth::before 
  background: #e65124


.card-body.custom_card_body.first::before 
  background: #9c9400


.card-body.custom_card_body.second::before 
  background: #27953e


.card-body.custom_card_body.third::before 
  background: #53479e


.dataTables_wrapper.dt-bootstrap4.no-footer.no-width table 
  min-width: inherit


.mobile_show_logo 
  display: none


.toggle2 
  display: none
  position: absolute
  top: 29%
  left: right
  right: 130px

ol.breadcrumb li.breadcrumb-item a 
  font-size: 14px
  font-weight: 600
  color: rgba(0, 0, 0, 0.8)
  cursor: pointer


ol.breadcrumb .breadcrumb-item.active 
   font-size: 14px
   font-weight: 600
   color: #28a745
    background-clip: text
    -webkit-background-clip: text
   


@media only screen and (max-width: 1400px) 
  .user_card h2 
    font-size: 16px
  
  .custom_img_card i 
    font-size: 42px
  
  .card-body.custom_card_body::before 
    max-width: 84px
  


@media only screen and (max-width: 992px) 
  .app-sidebar 
    transform: translateX(-230px)
    transition: 0.3s
  
  .footer 
    margin-left: 0
  
  .app-sidebar-open 
    transform: translateX(0px)
    transition: 0.3s
  
  .form_edit_book 
    padding: 15px 15px
  
  .toggle2 
    display: block
    cursor: pointer
    right: 248px
  
  .app-content 
    margin-left: 0
  


@media only screen and (max-width: 767px) 
  ul.side-menu 
    margin: 0
  
  a.nav-link.pr-0.leading-none 
    width: 98px
    padding-left: 4px
  
  .toggle2 
    right: 124px
  


.table-responsive.custom_table_responsive.mt-0.feedback_table 
  overflow-x: hidden

.table-responsive.custom_table_responsive.mt-0.feedback_table
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  max-width: 200px

.form_edit_book.promocode_txt_label .promocode_txt span 
  font-size: 16px
  color: #00000082
  display: block
  margin-top: 10px

.form_edit_book.promocode_txt_label 
  padding-top: 30px
  padding-bottom: 5px

#block_user .modal-dialog.modal-sm 
  margin-top: 200px

/* .dataTables_wrapper.dt-bootstrap4.no-footer.no-width.user_tabel_view
  table.dataTable
  tbody
  td 
  padding-left: 20px !important
 */
/* Avtar .dataTables_wrapper.dt-bootstrap4.no-footer.no-width.user_tabel_view
  table.dataTable
  tbody
  td
  .flex_fa_icons 
  justify-content: unset
 */
.dataTables_wrapper.dt-bootstrap4.no-footer.no-width.user_tabel_view
  padding-left: 0

.cam_rel 
  position: relative

.cam_rel .cam_edit_dp 
  position: absolute
  right: 0
  bottom: 0

label.cam_edit_dp_bg .cam_edit_dp 
  position: absolute
  top: 7px
  right: 10px

.form-group.input_txt.bod_place input 
  text-transform: capitalize
  background: #fff !important
  border-radius: 6px
  outline: none !important
  box-shadow: none !important
  padding: 14px 18px
  color: #222 !important
  border: 1px solid #ababab !important

.form-group.input_txt.bod_place input::placeholder 
  color: rgba(0, 0, 0, 0.5)

.mydp, .mydp .headertodaybtn 
  border: none !important

#block_user .modal-footer a.back_btn.add_content 
  text-transform: uppercase

#block_user .modal-footer a.back_btn.add_content:hover 
  color: #fff

input.selection.ng-valid.ng-dirty.ng-touched 
  width: 100%
  padding: 14px 18px
  background: #f1f3f6
  border: none !important
  border-radius: 6px
  outline: none !important
  box-shadow: none !important

.dataTables_wrapper.dt-bootstrap4.no-footer.view_user_table_component
  padding-left: 10px !important

.dataTables_wrapper.dt-bootstrap4.no-footer.mange_table_component
  justify-content: unset

.dataTables_wrapper.dt-bootstrap4.no-footer.mange_table_component
  padding-left: 0

.form_edit_book .form-group.input_txt input:focus 
  background: #f1f3f6

.form_edit_book .form-group.input_txt input::placeholder 
  font-size: 16px
  color: #222
  font-weight: 400

.form_edit_book.all_custom_input .form-group.input_txt input::placeholder 
  font-size: 16px
  color: rgba(0, 0, 0, 0.5)
  font-weight: 400

.form_edit_book.all_custom_input .form-group.input_txt input 
  font-size: 16px
  color: #222
  background: #fff
  border: 1px solid #ababab

.form_edit_book.all_custom_input .form-group.input_txt select 
  text-indent: 0px
  padding-left: 14px !important

.container.block_user_popup .modal-footer a,
.container.block_user_popup .modal-footer button 
  text-transform: uppercase
  font-size: 14px
  font-weight: 600
  color: #fff !important

a.btn.btn-light.pl-6.pr-6 
  background-color: #E2E6E8
  border-color: #dae0e5

.container.block_user_popup .modal-footer a:hover 
  color: #fff

.container.block_user_popup .modal-dialog.modal-sm 
  margin-top: 200px

ul.dropdown-menu.chapter_subs_color 
  background-image: linear-gradient(481deg, #e65124, #9c9400, #27953e, #53479e)
  border: none
  border: 2px solid #fff
  left: 7px !important

ul.dropdown-menu.chapter_subs_color li a span.side-menu__label 
  color: #fff !important 

.dash_header .d-flex.flex_row_custom 
  padding-top: 58px
  justify-content: space-between

.dash_header .dash_logo a 
  position: absolute
  left: 0
  right: 0
  top: 0
  text-align: center
  padding-top: 2px

.dash_header .dash_logo a img 
  width: 208px
  position: absolute
  margin: auto
  left: 0
  right: 0
  transition: 0.3s

.dash_header .header_icon_left ul li a,
.dash_header .header_icon_right ul li a,
.dash_header .dropdown button.btn.btn-primary.dropdown-toggle 
  font-size: 16px 
  font-weight: 400
  color: #222
  padding-bottom: 10px


.dash_header .header_icon_left ul li:nth-of-type(2) 
  padding-top: 0

.dash_header .app-header .dropdown 
  border-left: none

.dash_header .dropdown button.btn.btn-primary.dropdown-toggle 
  padding: 0
  padding-bottom: 10px

.dash_header .header_icon_left ul li a:hover,
.header_icon_right ul li a:hover,
.dash_header .dropdown button.btn.btn-primary.dropdown-toggle:hover 
  background-image: linear-gradient(481deg, #e65124, #9c9400, #27953e, #53479e)
  background-clip: text
  -webkit-background-clip: text
  color: transparent
  transition: 0.2s

.dash_header .dropdown button.btn.btn-primary.dropdown-toggle:hover span 
  color: #222

.dash_header .dropdown ul.dropdown-menu li 
  width: 100%
  margin-right: 0

.dash_header .dropdown ul.dropdown-menu li a 
  padding: 8px 10px

.dash_header .dropdown ul.dropdown-menu 
  background: #fff
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08)
  border: none
  border-radius: 4px

.pt_flex 
  padding-top: 39px !important
  margin-top: -11px !important

.dash_header .dropdown:hover 
  background: none

.dash_header_mobile_menu 
  display: none

.mobile_flex_menu_toggle 
  display: none
  margin-top: 22px

.mobile_flex_menu_toggle span 
  display: block
  width: 30px
  height: 2px
  border-radius: 4px
  margin: 6px 0
  background-image: linear-gradient(481deg, #e65124, #9c9400, #27953e, #53479e)

.dash_header_mobile_menu ul.navbar-nav li a 
  display: block
  padding: 10px 23px
  font-size: 16px
  font-weight: 400
  color: #222
  width: 100%
  font-family: "baskervillenormal"

.dash_header_mobile_menu .dropdown button.btn.btn-primary.dropdown-toggle 
  padding: 0
  padding: 10px 23px
  font-family: "baskervillenormal" !important
  font-size: 16px !important


.dash_header_mobile_menu nav.navbar.navbar-expand-lg.navbar-light 
  margin-bottom: -24px !important

@media only screen and (max-width: 992px) 
  .dash_header 
    display: none
  
  .dash_header .d-flex.flex_row_custom 
    justify-content: unset
    position: absolute
    background: #fff
    width: 100%
    left: 0
  
  .dash_header_mobile_menu 
    display: block
  
  .dash_header_mobile_menu .mobile_flex_menu 
    display: flex
    justify-content: space-between
  
  .dash_header_mobile_menu .mobile_show_logo 
    display: block
    height: 53px
  
  .dash_header_mobile_menu .menus_list li 
    list-style-type: none
  
  .mobile_flex_menu_toggle 
    display: block
  
  .dash_header_mobile_menu .menus_list 
    position: absolute
    top: 77px
    background: #fff
    width: 100%
    left: 0
  
  .app-content .side-app 
    padding: 40px 15px
  

@media only screen and (max-width: 767px) 
  .dash_header_mobile_menu 
    display: block
    height: 72px
  


table.dataTable thead th,
table.dataTable thead td 
  padding: 10px 8px !important

.form_edit_book .form-group.input_txt label, .form-group.input_txt label 
  font-weight: 500
  color: #222
  font-size: 16px

label.cam_rel 
  position: relative

label.cam_rel img.edit_btn_pic 
  position: absolute
  border-radius: 0px !important
  border: none !important
  width: 30px !important
  height: 30px !important
  bottom: 4px
  right: 1px

label.cam_rel::before 
  content: ""
  position: absolute
  width: 130px
  height: 130px
  border: 2px solid #fff
  left: -5px
  right: 0
  top: -5px
  margin: auto
  border-radius: 50%

.form_edit_book.promocode_txt_label .promocode_txt p 
  font-size: 16px
  color: #222
  margin: 0
  font-weight: 500

.form-group.input_txt.bod_place input 
  font-size: 16px !important
  padding: 14px 18px !important
  height: 38px !important
  pointer-events: none !important

.dash_header .d-flex.flex_row_custom button.btn.btn-primary.dropdown-toggle i 
  border: solid black
  border-width: 0 2px 2px 0
  display: inline-block
  padding: 3px
  transform: rotate(45deg)
  -webkit-transform: rotate(45deg)
  margin-left: 2px
  margin-right: 5px
  margin-bottom: 2px

.dash_header
  content: none

.dash_header .header_icon_left ul li a.active 
  // background-image: linear-gradient(481deg, #e65124, #9c9400, #27953e, #53479e)
  background-clip: text
  -webkit-background-clip: text
  // color: transparent
  -webkit-transition: 0.2s
  transition: 0.2s

.heading_form h2 
  text-align: center

input:focus 
  color: rgba(0, 0, 0, 0.5)

.form_edit_book.all_custom_input input.site_btn 
  border: none
  outline: none

td label.switch.yes 
  font-size: 13px
  color: #6b6f80
  font-weight: 500

td label.switch.blocked 
  font-size: 13px
  color: #6b6f80
  font-weight: 500

.mydp .selbtngroup 
  right: 13px !important
  top: 1px !important

td label.switch.blocked,
td label.switch.yes 
  height: 30px
  padding-top: 5px

td label.switch.blocked .slider:before,
td label.switch.yes .slider:before 
  bottom: -3px

.dash_header .dropdown button.btn.btn-primary.dropdown-toggle img 
  position: relative
  top: -4px
  padding-right: 5px

.dash_header .header_icon_left ul li a img
  padding-right: 0px


.dash_header .dropdown ul.dropdown-menu:before 
  content: ''
  position: absolute
  width: 30px
  height: 30px
  transform: rotate(45deg)
  background: #fff
  display: block
  z-index: -1
  left: 0
  right: 0
  margin: auto


/* on-off */

.onoffswitch 
  position: relative
  width: 61px
  -ms-user-select: none


.onoffswitch-checkbox 
  display: none

a.back_btn.add_content.pl-4.pr-4 
  font-size: 20px

.onoffswitch-label 
  display:  block 
  overflow: hidden
  cursor: pointer
  border-radius: 9px 
  border: 1px solid #4ca746


.onoffswitch-inner 
  display: block 
  width: 200% 
  margin-left: -100%
  transition: margin 0.3s ease-in 0s
  opacity: 1


label.onoffswitch-label 
  margin-bottom: 0

.onoffswitch-inner:before, .onoffswitch-inner:after 
  display: block 
  float: left
  width: 50% 
  height: 26px
  padding: 0 
  line-height: 26px
  font-size: 14px
  color: white
  font-family: Trebuchet, Arial, sans-serif
  font-weight: bold
  box-sizing: border-box


 


a.back_btn.add_content.pl-4.pr-4 
  font-size: 44px
  padding: 1px 9px !important

.onoffswitch-inner:before 
  content: "No"
  padding-left: 16px
  opacity: 1
  color: #fff
  font-size: 16px
  font-weight: 500
  background: #4ca746
  display: flex
  align-items: center


.onoffswitch-inner:after 
  content: "Yes"
  padding-right: 11px
  opacity: 1
  color: #fff
  text-align: right
  font-size: 16px
  font-weight: 500
  background: #dc3544
  display: flex
  justify-content: flex-end
  align-items: center


.onoffswitch-switch 
  display: block
  width: 20px
  margin: 3px
  background: #fff
  position: absolute
  top: 0
  bottom: 0
  right: 45px
  margin: 0px
  width: 15px !important

  border-radius: 9px
  transition: all 0.3s ease-in 0s 
  height: 26px
  top:1px

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner 
  margin-left: 0


.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch 
  right: 1px 
  background-color: #fff 


.onoffswitch-checkbox:checked + .onoffswitch-label
  border: 1px solid #4ca746


